import React from "react";
import logo from "../../static/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { RiMenu3Fill } from "react-icons/ri";

const navigationItems = ["Home", "Features", "Contact", "Pricing"];



function Navbar({ isScrolledToEnquiry }) {

  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = React.useState({
    right: false,
  });

  const links = [
    { name: "Home", href: "/" },
    { name: "Features", href: "#" },
    { name: "Contact", href: "#" },
    { name: "Pricing", href: "#" },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleNavigation = (path) => {
    if (path === "close") {
      toggleDrawer("right", false)(); // Close the drawer
    } else {
      navigate(path);
      toggleDrawer("right", false)(); // Close the drawer after navigation
    }
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding>
          <a
            href="/"
            className="flex w-full items-center justify-center pt-5 pb-10 "
          >
            <img src={logo} alt="Logo" className="h-4" />
            <span className="ps-1 font-medium text-2xl mac:text-3xl custom-font">
              REBS
            </span>
          </a>
        </ListItem>
        <div className="ps-5">
          {navigationItems.map((text) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() =>
                  handleNavigation(
                    text === "Close"
                      ? "close"
                      : text === "Home"
                      ? "/"
                      : `/${text.toLowerCase()}`
                  )
                }
                className="text-xl"
              >
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{ style: { fontSize: "0.875rem" } }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </div>
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <a
        href="/"
        className="flex justify-center items-center p-4 text-[10px] text-center"
      >
        Powered by Teqbae innovations & solutions (India) pvt ltd
      </a>
    </Box>
  );

  return (
    <div className="w-full fixed top-0 z-[100] bg-stone-50 text-black ">
      <div className="h-14 lg:h-20 w-full ps-5 md:px-10 flex justify-between items-center ">
        <div className="flex items-center">
          <img src={logo} className="h-4" alt="Logo" />
          <p className="ps-1 font-medium text-2xl mac:text-3xl custom-font">
            REBS
          </p>
        </div>
        <div className="hidden lg:flex ms-[8%] flex justify-between items-center text-gray-600 p-1 mt-1 py-2 border-2 border-black rounded-full">
          <div className="flex items-center justify-between flex-grow">
            <div className="flex justify-center items-center text-white font-bold rounded-full transition duration-300">
              {links.map((link, index) => (
                <a
                  key={index}
                  href={link.href}
                  className={`font-semibold mx-1 py-2 px-4 text-xs rounded-full transition duration-300 
            ${
              location.pathname === link.href
                ? "bg-black text-white"
                : "bg-transparent text-neutral-700 hover:text-white hover:bg-black"
            }`}
                >
                  {link.name}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="hidden lg:flex">
          <div className="flex gap-x-10 items-center">
            <p className="font-semibold">Login</p>
            <div className="border-2 font-semibold border-black rounded-3xl py-2 px-6">
              <p>Get Started</p>
            </div>
          </div>
        </div>
        <div className="lg:hidden flex items-center">
          <Button onClick={toggleDrawer("right", true)}>
            <RiMenu3Fill className="text-black" size={22} />
          </Button>
          <SwipeableDrawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
          >
            {list("right")}
          </SwipeableDrawer>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
