import React from "react";
import Navbar from "../navbar/Navbar";
import LandingPage from "../landing/LandingPage";
import EnquiryPage from "../enquiry/EnquiryPage";

function Home() {
  return (
    <div className="bg-black">
      <div className="h-screen">
        <div className="w-full fixed top-0 z-[60] bg-stone-100 text-black">
          <Navbar />
        </div>
        <div className="h-[100vh] bg-slate-50">
          <LandingPage />
        </div>
      </div>
      <div className="mt-20 px-5 lg:px-20 xl:px-32">
        <EnquiryPage />
      </div>
    </div>
  );
}

export default Home;
