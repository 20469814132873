import axiosInstance from './axiosInstance';
import { enquries, countryId, organisationType, makePayment } from './api';

export const submitEnquiry = async (formData) => {
  try {
    const response = await axiosInstance.post(enquries, formData);
    //console.log("response  : ",response)
    return response.data;  
  } catch (error) {
    console.error('Error submitting enquiry:', error);
    throw error;  
  }
};

export const fetchCountries = async () => {
  try {
    const response = await axiosInstance.get(countryId);
    //console.log('response : ',response.data.data)
    return response.data.data; 
  } catch (error) {
    console.error('Error fetching countries:', error);
    throw error;
  }
};

export const fetchOrganisation = async () => {
  try {
    const response = await axiosInstance.get(organisationType);
    //console.log('response : ',response.data.data)
    return response.data.data; 
  } catch (error) {
    console.error('Error fetching organisationType :', error);
    throw error;
  }
};

export const makePayments = async (formData) => {
  try {
    const response = await axiosInstance.post(makePayment, formData);
    return response.data;
  } catch (error) {
    console.error('Error initiating payment:', error);
    throw error;
  }
};