import React from "react";
import EnquiryFrom from "./EnquiryFrom";
import doublearrow from "../../static/images/double-arrow.png";
import formimg from "../../static/images/form-side-img.jpg";

function EnquiryPage() {
  return (
    <div className="flex w-full">
      <div className="w-full">
        <div className="lg:w-[45%] xl:w-[45%] 2xl:w-[40%] 3xl:w-1/3">
          <img src={doublearrow} className="h-12 mac:h-16"/>
          <p className="text-white text-xl lg:text-2xl mac:text-4xl font-bold">Enquiry Today!</p>
          <p className="pt-5 text-xs lg:text-sm mac:text-base text-neutral-500">
            Enter your details to begin your enquiry. Upon successful payment,
            you'll receive your login credentials to access our tailored
            solutions.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row w-full pb-10">
          <div className="w-full lg:w-1/2 mt-10 pe-3 mac:pe-5">
            <EnquiryFrom />
          </div>
          <div className="w-full lg:w-1/2 ps-3 mac:ps-5 mt-10 ">
            <div className="w-full h-1/2 object-cover rounded-3xl overflow-hidden">
              <img src={formimg} className="h-full w-full object-cover rounded-3xl"/>
            </div>
            <div className="w-full h-1/2 flex pt-5 ">
              <div className="w-1/2 h-full me-3 mac:me-5 px-5 2xl:px-10  md:py-8 lg:py-0 flex flex-col justify-center bg-neutral-900 rounded-3xl">
                <p className="text-white pt-4 md:pt-2 text-sm lg:text-base 2xl:text-lg mac:text-2xl font-semibold">
                  TEQBAE INNOVATIONS: Driving Business Success
                </p>
                <p className="py-5 2xl:py-7 mac:py-5 md:pe-10 xl:pe-10 mac:pe-16 text-xs mac:text-base text-neutral-500">
                  We deliver innovative, reliable software solutions tailored to
                  help businesses thrive with cutting-edge technology and
                  exceptional service.
                </p>
              </div>
              <div className="w-1/2 ms-3 mac:ms-5 px-4 md:px-8 xl:px-10 md:py-8 lg:py-0 flex flex-col justify-center bg-neutral-900 rounded-3xl">
                <div className="">
                  <p className="text-xs mac:text-base text-neutral-500">
                    email
                  </p>
                  <p className="text-white text-sm xl:text-base mac:text-lg font-semibold">
                    ebox.teqbae.com
                  </p>
                </div>
                <div className="py-5 2xl:py-7">
                  <p className="text-xs mac:text-base text-neutral-500">
                    Phone
                  </p>
                  <p className="text-white text-sm xl:text-base mac:text-lg font-semibold">
                    +91 9947844484
                  </p>
                </div>
                <div className="">
                  <p className="text-xs mac:text-base text-neutral-500">
                    website
                  </p>
                  <p className="text-white text-sm xl:text-base mac:text-lg font-semibold">
                    www.teqbae.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnquiryPage;
