import React, { useState, useEffect } from "react";
import {
  submitEnquiry,
  fetchCountries,
  fetchOrganisation,
  makePayments
} from "../../service/enquiry";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EnquiryFrom() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    website: "",
    organization: "",
    country: "",
    pan: "",
    contactPerson: "",
    address: "",
  });

  const [countries, setCountries] = useState([]);
  const [organisation, setOrganisation] = useState([]);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    website: "",
    organization: "",
    country: "",
    pan: "",
    contactPerson: "",
    address: "",
  });

  useEffect(() => {
    const loadCountries = async () => {
      try {
        const data = await fetchCountries();
        setCountries(data.countries || data);
      } catch (error) {
        console.error("Failed to load countries:", error);
      }
    };

    loadCountries();
  }, []);

  useEffect(() => {
    const loadOrganisation = async () => {
      try {
        const data = await fetchOrganisation();
        setOrganisation(data.countries || data);
      } catch (error) {
        console.error("Failed to load countries:", error);
      }
    };

    loadOrganisation();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      newErrors.name = "Company name is required";
      isValid = false;
    } else if (formData.name.trim().length < 2) {
      newErrors.name = "Company name must be at least 2 characters long";
      isValid = false;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email.trim()) {
      newErrors.email = "Email address is required";
      isValid = false;
    } else if (!emailPattern.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    const phonePattern =/^(\+?)?[0-9]{12}$/;
    if (!formData.phone.trim()) {
      newErrors.phone = "Mobile number is required";
      isValid = false;
    } else if (!phonePattern.test(formData.phone)) {
      newErrors.phone = "Please enter a valid mobile number with country code";
      isValid = false;
    }

    const websitePattern =
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-])\/?$/;
    if (!formData.website.trim()) {
      newErrors.website = "Website link is required";
      isValid = false;
    } else if (!websitePattern.test(formData.website)) {
      newErrors.website = "Please enter a valid website URL";
      isValid = false;
    }

    if (!formData.organization.trim()) {
      newErrors.organization = "Organization type is required";
      isValid = false;
    }

    if (!formData.country) {
      newErrors.country = "Please select a country";
      isValid = false;
    }

    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (!formData.pan.trim()) {
      newErrors.pan = "PAN number is required";
      isValid = false;
    } else if (!panPattern.test(formData.pan.toUpperCase())) {
      newErrors.pan = "Please enter a valid PAN number";
      isValid = false;
    }

    if (!formData.contactPerson.trim()) {
      newErrors.contactPerson = "Contact person name is required";
      isValid = false;
    } else if (formData.contactPerson.trim().length < 2) {
      newErrors.contactPerson =
        "Contact person name must be at least 2 characters long";
      isValid = false;
    }

    if (!formData.address.trim()) {
      newErrors.address = "Address is required";
      isValid = false;
    } else if (formData.address.trim().length < 10) {
      newErrors.address = "Please provide a more detailed address";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const requestData = {
      name: formData.name,
      email: formData.email,
      address: formData.address,
      country: formData.country,
      website: formData.website,
      organisation_type: formData.organization,
      contact_person: formData.contactPerson,
      pan: formData.pan,
      phone_number: formData.phone,
    };

    try {
      const response = await submitEnquiry(requestData);

      const paymentData = {
        name: formData.name,
        email: formData.email,
        phone_number: formData.phone,
      };

      //console.log('data : ',paymentData)
  
      const paymentResponse = await makePayments(paymentData);
      //toast.success("Payment process initiated successfully!");
  
      setFormData({
        name: "",
        email: "",
        phone: "",
        website: "",
        organization: "",
        country: "",
        pan: "",
        contactPerson: "",
        address: "",
      });
      toast.success("Your enquiry has been submitted!");
    } catch (error) {
      //console.error("Failed to submit enquiry:", error);
      toast.error("This email is already registered. Please use a different email.");
    }
  };

  return (
    <div>
      <div className="py-5 lg:py-10 mac:py-16 bg-neutral-900 shadow-lg rounded-3xl">
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
        <form onSubmit={handleSubmit} className="px-5 lg:px-10 mac:px-16">
          <div className="mb-3 mac:mb-8 flex items-center border border-gray-600 hover:border-white transition-all duration-300 ease-in-out rounded-3xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="36px"
              className="ps-4 fill-gray-400"
            >
              <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480v58q0 59-40.5 100.5T740-280q-35 0-66-15t-52-43q-29 29-65.5 43.5T480-280q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480v58q0 26 17 44t43 18q26 0 43-18t17-44v-58q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93h200v80H480Zm0-280q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z" />
            </svg>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="company name"
              value={formData.name}
              onChange={handleChange}
              className="w-[90%] ps-4 pe-4 py-3 mac:py-3 outline-none text-sm mac:text-base text-white bg-transparent "
              required
            />
          </div>
          {errors.name && (
            <div className="text-red-500 text-xs ">{errors.name}</div>
          )}

          <div className="mb-3 mac:mb-8 flex items-center border border-gray-600 hover:border-white transition-all duration-300 ease-in-out rounded-3xl mt-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="36px"
              className="ps-4 fill-gray-400"
            >
              <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
            </svg>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="email address"
              value={formData.email}
              onChange={handleChange}
              className="w-[90%] ps-4 pe-4 py-3 mac:py-3 outline-none text-sm mac:text-base text-white bg-transparent"
              required
            />
          </div>
          {errors.email && (
            <div className="text-red-500 text-xs ">{errors.email}</div>
          )}

          <div className="mb-3 mac:mb-8 flex items-center border border-gray-600 hover:border-white transition-all duration-300 ease-in-out rounded-3xl mt-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="30px"
              viewBox="0 -960 960 960"
              width="38px"
              className="ps-4 fill-gray-400"
            >
              <path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" />
            </svg>
            <input
              type="text"
              id="website"
              name="website"
              placeholder="website link"
              value={formData.website}
              onChange={handleChange}
              className="w-[90%] ps-4 pe-4 py-3 mac:py-3 outline-none text-sm mac:text-base text-white bg-transparent"
              required
            />
          </div>
          {errors.website && (
            <div className="text-red-500 text-xs ">{errors.website}</div>
          )}

          <div className="w-full flex justify-between mt-8">
            <div className="w-1/2 me-2 flex items-center border border-gray-600 hover:border-white transition-all duration-300 ease-in-out rounded-3xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="34px"
                className="ps-4 fill-gray-400"
              >
                <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
              </svg>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="mobile number"
                value={formData.phone}
                onChange={handleChange}
                className="w-[90%] ps-4 pe-4 py-3 mac:py-3 outline-none text-sm mac:text-base text-white bg-transparent"
                required
              />
            </div>
            <div className="w-1/2 ms-2 flex items-center border border-gray-600 hover:border-white transition-all duration-300 ease-in-out rounded-3xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="36px"
                className="ps-4 fill-gray-400"
              >
                <path d="M80-120v-720h400v160h400v560H80Zm80-80h240v-80H160v80Zm0-160h240v-80H160v80Zm0-160h240v-80H160v80Zm0-160h240v-80H160v80Zm320 480h320v-400H480v400Zm80-240v-80h160v80H560Zm0 160v-80h160v80H560Z" />
              </svg>
              <select
                id="organization"
                name="organization"
                value={formData.organization}
                onChange={handleChange}
                className={`w-full ps-4 pe-4 py-3 outline-none text-sm mac:text-base bg-transparent me-3 ${
                  formData.organization === "" ? "text-gray-400" : "text-white"
                }`}
                required
              >
                <option
                  value=""
                  className="bg-neutral-900 text-gray-400 hover:bg-red-500"
                >
                  Select a Organisation
                </option>
                {organisation.map((organisation) => (
                  <option
                    key={organisation.id}
                    value={organisation.id}
                    className="bg-neutral-900 text-white hover:bg-red-500 "
                  >
                    {organisation.name}
                  </option>
                ))}
              </select>
              {/* <input
                type="text"
                id="organization"
                name="organization"
                placeholder="organisation type"
                value={formData.organization}
                onChange={handleChange}
                className="w-[90%] ps-4 pe-4 py-3 mac:py-3 outline-none text-sm mac:text-base text-white bg-transparent"
                required
              /> */}
            </div>
          </div>
          {errors.phone && (
            <div className="text-red-500 text-xs ">{errors.phone}</div>
          )}

          <div className="w-full flex justify-between mt-8">
            <div className="w-1/2 me-2 flex items-center border border-gray-600 hover:border-white transition-all duration-300 ease-in-out rounded-3xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                className="ps-4 fill-gray-400"
              >
                <path d="M200-120v-680h360l16 80h224v400H520l-16-80H280v280h-80Zm300-440Zm86 160h134v-240H510l-16-80H280v240h290l16 80Z" />
              </svg>
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                className={`w-full ps-2 md:ps-4 pe-4 py-3 outline-none text-sm mac:text-base bg-transparent me-3 ${
                  formData.organization === "" ? "text-gray-400" : "text-white"
                }`}
                //className="w-full ps-4 pe-4 py-3 outline-none text-sm mac:text-base text-white bg-transparent me-3"
                required
              >
                <option
                  value=""
                  className="bg-neutral-900 text-gray-400 hover:bg-red-500"
                >
                  Select a country
                </option>
                {countries.map((country) => (
                  <option
                    key={country.id}
                    value={country.id}
                    className="bg-neutral-900 text-white hover:bg-red-500 "
                  >
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            {errors.country && (
              <div className="text-red-500 text-xs ">{errors.country}</div>
            )}
            <div className="w-1/2 ms-2 flex items-center border border-gray-600 hover:border-white transition-all duration-300 ease-in-out rounded-3xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="36px"
                className="ps-4 fill-gray-400"
              >
                <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
              </svg>
              <input
                type="text"
                id="pan"
                name="pan"
                placeholder="pan number"
                value={formData.pan}
                onChange={handleChange}
                className="w-[90%] ps-4 pe-4 py-3 mac:py-3 outline-none text-sm mac:text-base text-white bg-transparent"
                required
              />
            </div>
          </div>
          {errors.pan && (
            <div className="text-red-500 text-xs ">{errors.pan}</div>
          )}

          <div className="mb-3 mac:mb-8 flex items-center border border-gray-600 hover:border-white transition-all duration-300 ease-in-out rounded-3xl mt-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="35px"
              className="ps-4 fill-gray-400"
            >
              <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" />
            </svg>
            <input
              type="text"
              id="contactPerson"
              name="contactPerson"
              placeholder="contact person name"
              value={formData.contactPerson}
              onChange={handleChange}
              className="w-[90%] ps-4 pe-4 py-3 mac:py-3 outline-none text-sm mac:text-base text-white bg-transparent"
              required
            />
          </div>
          {errors.contactPerson && (
            <div className="text-red-500 text-xs ">{errors.contactPerson}</div>
          )}

          <div className="mb-3 mac:mb-8 flex items-center border border-gray-600 hover:border-white transition-all duration-300 ease-in-out rounded-3xl mt-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="35px"
              className="ps-4 fill-gray-400"
            >
              <path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z" />
            </svg>
            <input
              type="text"
              id="address"
              name="address"
              placeholder="address"
              value={formData.address}
              onChange={handleChange}
              className="w-[90%] ps-4 pe-4 py-3 mac:py-3 outline-none text-sm mac:text-base text-white bg-transparent"
              required
            />
          </div>
          {errors.address && (
            <div className="text-red-500 text-xs ">{errors.address}</div>
          )}

          <button
            className="mt-10 flex w-full justify-between gap-x-2 items-center mx-auto before:bg-gradient-to-r from-red-600 to-red-900 hover:text-white shadow-xl text-base mac:text-lg text-black bg-white backdrop-blur-md lg:font-semibold isolation-auto before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full hover:text-bg-gradient-to-r from-red-600 to-red-900 before:-z-10 before:aspect-square before:hover:scale-200 before:hover:duration-500 relative z-10 px-4 py-1 lg:py-2 overflow-hidden rounded-full group"
            type="submit"
          >
            {/* before:bg-gradient-to-r from-red-600 to-red-900 hover:text-white*/}
            <div></div>
            <p>Get started</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 -960 960 960"
              className="w-8 h-8 justify-end items-end bg-gradient-to-r from-red-600 to-red-900 group-hover:rotate-180 group-hover:bg-white text-gray-50 ease-linear duration-300 rounded-full p-2"
            >
              <path
                className="fill-white group-hover:fill-white group-hover:bg-white"
                d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"
              />
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
}

export default EnquiryFrom;

{
  /* <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFFFFF"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/></svg> */
}
