import React, { useState, useEffect, useRef } from "react";
import EnquiryPage from "../enquiry/EnquiryPage";
import "../../static/styles/styles.css";
import shine from "../../static/images/Rebs Title Vector.svg";
import pinkDesign from "../../static/images/Line vector.svg";
import Navbar from "../navbar/Navbar";
import adanim from "../../static/gif/Comp1_.gif";
import '../Cursor/cursor.css'


function LandingPage() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    class Particle {
      constructor(x, y, speedX, speedY) {
        this.x = x;
        this.y = y;
        this.speedX = speedX;
        this.speedY = speedY;
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        if (this.x > canvas.width || this.x < 0) {
          this.speedX = -this.speedX;
        }
        if (this.y > canvas.height || this.y < 0) {
          this.speedY = -this.speedY;
        }
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, 2, 0, Math.PI * 2);
        ctx.fillStyle = "gray";
        ctx.fill();
      }
    }

    const particles = [];
    const numParticles = 46;

    for (let i = 0; i < numParticles; i++) {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      const speedX = (Math.random() - 0.5) * 1.2;
      const speedY = (Math.random() - 0.5) * 1.2;
      particles.push(new Particle(x, y, speedX, speedY));
    }

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (const particle of particles) {
        particle.update();
        particle.draw();
      }

      for (let i = 0; i < particles.length; i++) {
        for (let j = i + 1; j < particles.length; j++) {
          const dx = particles[i].x - particles[j].x;
          const dy = particles[i].y - particles[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 110) {
            ctx.strokeStyle = "rgb(54, 69, 79)";
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(particles[i].x, particles[i].y);
            ctx.lineTo(particles[j].x, particles[j].y);
            ctx.stroke();
          }
        }
      }
    }

    animate();
  }, []);

  useEffect(() => {
    const cursor = document.querySelector('.cursor');
    const cursorinner = document.querySelector('.cursor2');
    const links = document.querySelectorAll('a');

    const handleMouseMove = (e) => {
      const x = e.clientX;
      const y = e.clientY;
      cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
      cursorinner.style.left = `${x}px`;
      cursorinner.style.top = `${y}px`;
    };

    const handleMouseDown = () => {
      cursor.classList.add('click');
      cursorinner.classList.add('cursorinnerhover');
    };

    const handleMouseUp = () => {
      cursor.classList.remove('click');
      cursorinner.classList.remove('cursorinnerhover');
    };

    const handleLinkHover = (e) => {
      cursor.classList.add('hover');
    };

    const handleLinkLeave = (e) => {
      cursor.classList.remove('hover');
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    links.forEach((link) => {
      link.addEventListener('mouseover', handleLinkHover);
      link.addEventListener('mouseleave', handleLinkLeave);
    });

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);

      links.forEach((link) => {
        link.removeEventListener('mouseover', handleLinkHover);
        link.removeEventListener('mouseleave', handleLinkLeave);
      });
    };
  }, []);

  return (
    <div className="h-full w-full flex flex-col justify-between md:items-center bg-slate-50 rounded-b-[50px] lg:rounded-b-[100px] overflow-hidden">
      <div className="cursor"></div>
      <div className="cursor2"></div>
      <canvas
        id="canvas"
        ref={canvasRef}
        className="absolute z-10 w-full h-full bg-zinc-50"
      ></canvas>
      <div></div>
      <div className="md:items-center px-5 md:px-0 bg-slate-50 z-50">
        <div className="flex flex-col md:justify-center items-start md:items-center">
          <div className="w-full hidden md:flex md:w-[97%] flex justify-between items-end">
            <img src={pinkDesign} className="h-6 lg:h-10" />
            <img src={shine} className="h-6 lg:h-8" />
          </div>
          <div className="flex md:hidden -mt-3 md:px-5 lg:px-8 sc369:text-2xl sc396:text-3xl sc420:text-4xl text-3xl md:text-3xl xl:text-4xl 2xl:text-5xl 4xl:text-6xl mac:text-6xl">
            <p className="text-black w-full text-left md:text-center font-bold">
              Automate Your <br className="md:hidden" />
              Business With&nbsp;
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-red-600 to-red-900">
                {" "}
                REBS{" "}
              </span>
            </p>
            <img src={shine} className="h-6 lg:h-8 md:hidden flex mt-4 -ms-3" />
          </div>

          <p className="text-black hidden md:flex w-full flex text-left md:text-center -mt-3 md:px-5 lg:px-8 text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl 4xl:text-6xl mac:text-6xl font-bold">
            Automate Your <br className="md:hidden" />{" "}
            <img src={pinkDesign} className="h-6 lg:h-10 md:hidden" />
            Business With&nbsp;
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-red-600 to-red-900">
              {" "}
              REBS{" "}
            </span>
          </p>
          <div className="flex flex-col justify-center items-center md:px-0">
            <p className="text-black text-left md:text-center pt-5 text-xs md:text-sm xl:text-base 2xl:text-lg mac:text-xl leading-loose">
              Connect with us to explore software solutions{" "}
              <br className="md:hidden" /> that automate tasks, boost
              efficiency,
              <br className="hidden md:block" /> and{" "}
              <br className="md:hidden" /> free up your team for strategic
              growth.
            </p>
          </div>
        </div>
      </div>
      <div className="sc369:h-56 sc396:h-56 h-80 lg:h-80 xl:h-96 z-50">
        <img
          src={adanim}
          className="h-full w-full object-cover"
          alt="Description of GIF"
        />
      </div>
    </div>
  );
}

export default LandingPage;
